exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-cookies-index-tsx": () => import("./../../../src/pages/cookies/index.tsx" /* webpackChunkName: "component---src-pages-cookies-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-index-tsx": () => import("./../../../src/pages/legal/index.tsx" /* webpackChunkName: "component---src-pages-legal-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-news-[title]-index-tsx": () => import("./../../../src/pages/news/[title]/index.tsx" /* webpackChunkName: "component---src-pages-news-[title]-index-tsx" */),
  "component---src-pages-noticias-index-tsx": () => import("./../../../src/pages/noticias/index.tsx" /* webpackChunkName: "component---src-pages-noticias-index-tsx" */),
  "component---src-pages-partners-[name]-index-tsx": () => import("./../../../src/pages/partners/[name]/index.tsx" /* webpackChunkName: "component---src-pages-partners-[name]-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */)
}

